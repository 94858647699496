import './HighscoreList.scss';

import { useData } from '../../hooks/useData';
import { Hackathon, HackathonUser } from '../../types/hackathon';
import { apiBase } from '../../api-config';
import { useHackathonStore } from '../../store';

export const HighscoreList = () => {
  const hackathonId = useHackathonStore(state => state.hackathonId);
  const [hackathons, _] = useData<Hackathon>(`${ apiBase }/api/v1/hackathlon/${ hackathonId }`);

  const sortUsers = (users: HackathonUser[] | undefined) => {
    return users?.sort((a, b) => a.xp === b.xp ? a.attempts - b.attempts : b.xp - a.xp)?.slice(0, 15)
        ?? null;
  };
  return (
      <div className="highscore-list">
        <h3>Highscore</h3>

        { !hackathons ? 'Loading highscores...' : (
            <table className="table table-striped">
              <thead>
              <tr>
                <th className="col-2">
                  XPs
                </th>
                <th>
                  Nick
                </th>
              </tr>
              </thead>
              <tbody>
              { sortUsers(hackathons?.hackathlonUsers)?.map(x => (
                  <tr key={ x.nick }>
                    <td className="text-right">
                      { x.xp }
                    </td>
                    <td>
                      <b>{ x.nick }</b>
                    </td>
                  </tr>
              )) }
              </tbody>
            </table>
        ) }
      </div>
  );
};
