import { Challenge } from '../../types/challenge';
import { Problem } from '../../types/problem';
import { ChallengeDetail } from './ChallengeDetail';
import { ProblemDetail } from '../problem/ProblemDetail';

export const ActiveChallenge = ({ challenge, problem }: { challenge: Challenge, problem: Problem }) => {
  return (
      <div>
        <ChallengeDetail challenge={ challenge } showActions={ false } />
        <hr />
        <ProblemDetail problem={ problem } />
      </div>
  );
};
