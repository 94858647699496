import './ChooseHackathonPage.scss';

import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { Hackathon } from '../types/hackathon';
import { useData } from '../hooks/useData';
import { useHackathonStore } from '../store';
import { format } from 'date-fns';
import { apiBase } from '../api-config';

export const ChooseHackathonPage = () => {
  const [hackathon, setHackathon] = useState('');
  const [hackathons, error] = useData<Hackathon[]>(`${ apiBase }/api/v1/hackathlon/active`);
  const setHackathonId = useHackathonStore(state => state.setHackathonId);
  const saveToken = useHackathonStore(state => state.setToken);

  if (hackathons?.length === 1 && !hackathon) setHackathon(hackathons[0].slug);

  const isAuthError = () => error?.response?.status === 401;
  const isOtherError = () => error && error.response?.status !== 401;
  const errorCode = () => error?.response?.status ?? 'Unknown';

  const chooseHackathon = () => setHackathonId(hackathon);
  const resetToken = () => saveToken(null);
  const formatDateTime = (date: string) => format(new Date(date), 'dd. MMM yyyy HH:mm');

  return (
      <div className="card choose-hackathon p-3 border border-primary rounded">
        <div className="card-title text-center">
          <h1>Choose a Hackathon</h1>
        </div>
        <div className="card-body">
          { isOtherError() ? <div className="alert alert-warning">
            Hmm... There seems to be a generic error (HTTP status { errorCode() }).<br />
            <a href="#0" onClick={ resetToken }>Go back & try again</a>!
          </div> : null }
          { isAuthError() ? <div className="alert alert-warning">
            Your token seems invalid, as access was denied.<br />
            <a href="#0" onClick={ resetToken }>Go back & try again</a>!
          </div> : null }
          { !isAuthError() && hackathons?.length === 0 ? <div className="alert alert-info">
            I'm sorry, but there is no Hackathon available at the moment!
          </div> : null }
          { !isAuthError() ? <div>
            { hackathons?.map(x => (
                <div className="card" key={ x.slug }>
                  <div className="card-body row">
                    <div className="row">
                      <div className="col flex-grow-1">
                        <b>{ x.name }</b>
                      </div>
                      <div className="col flex-shrink-1 flex-grow-0">
                        <Button onClick={ chooseHackathon } className="btn-sm">
                          Let's go!
                        </Button>
                      </div>
                    </div>
                    <p className="small mt-3">
                      Opens <b>{ formatDateTime(x.opens_at) }</b> and closes <b>{ formatDateTime(x.closes_at) }</b>
                    </p>
                  </div>
                </div>
            )) }
          </div> : null }
        </div>
      </div>
  );
};
