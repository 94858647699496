import './HackathonLayout.scss';
import { CodeEditorBase } from './code-editor/CodeEditorBase';
import { ChallengeBase } from './challenge/ChallengeBase';
import { useHackathonStore } from '../store';
import { SolveResponseTab } from './solveResponse/SolveResponseTab';
import { useData } from '../hooks/useData';
import { Problem } from '../types/problem';
import { Challenge } from '../types/challenge';
import { apiBase } from '../api-config';
import { useState } from 'react';
import { HighscoreList } from './highscore/HighscoreList';

export const HackathonLayout = () => {
  const showResponseTab = useHackathonStore(state => state.currentChallenge.successState);
  const solveResponseTabClasses = `col-12 col-sm-12 col-md-12 row code-tabs ${ showResponseTab !== null ? 'show-resolve-tab' : '' }`;
  const patchChallengeAction = useHackathonStore(state => state.patchChallenge);

  const currentHackathon = useHackathonStore(state => state.hackathonId);
  const [activeProblem, _] = useData<Problem | null>(`${ apiBase }/api/v1/hackathlon/${ currentHackathon }/active`);
  const [challenges, __] = useData<Challenge[]>(`${ apiBase }/api/v1/hackathlon/${ currentHackathon }/challenges`);
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(null);

  if (activeProblem && challenges) patchChallengeAction(challenges.find(x => x.name === activeProblem.challenge_name)!, activeProblem);

  return (
      <div className="row hackathon m-0 p-0">
        <div className="col-12 col-sm-12 col-md-6">
          <ChallengeBase selectedChallenge={ selectedChallenge } selectChallenge={ setSelectedChallenge } />
        </div>
        <div className="col-0 col-md-6">
          <HighscoreList></HighscoreList>
        </div>
        <div className={ solveResponseTabClasses }>
          <div className="col-12 code-editor-tab">
            <CodeEditorBase />
          </div>
          <div className="solve-response-tab">
            <SolveResponseTab selectChallenge={ setSelectedChallenge } />
          </div>
        </div>
      </div>
  );
};
