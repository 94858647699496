import './ProblemDetail.scss';
import { useEffect, useState } from 'react';

import { Problem } from '../../types/problem';
import { addSeconds, differenceInSeconds, formatDistanceStrict } from 'date-fns';
import { generateAttributesObject } from '../../lib/hackathonProblemLib';

export const ProblemDetail = ({ problem }: { problem: Problem }) => {
  const [remaining, setRemaining] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining(differenceInSeconds(addSeconds(Date.parse(problem.started_at), 120), new Date()));
    });

    return () => clearInterval(timer);
  }, [problem]);

  let distance: string;
  try {
    distance = formatDistanceStrict(Date.parse(problem.started_at), new Date(), { addSuffix: true });
  } catch (_) {
    distance = 'a few seconds ago';
  }

  return (
      <div className="problem-detail">
        { remaining > 0
            ? <h4>Solve the challenge, time is running!</h4>
            : <h4>Don't give up, even though time has ran out!</h4>
        }
        <p>
          Your problem is (this is the value of the <i>input</i> parameter):
        </p>
        <pre>
          { problem.problem.problem }
        </pre>
        <p>
          You receive the following object as <i>attributes</i> parameter to your function:
        </p>
        <pre>
          { generateAttributesObject(problem.problem) }
        </pre>
        <p>
          { remaining > 0
              ? <>You started this challenge { distance } ago, meaning you still have { remaining } seconds to go.</>
              : <p>
                <b>Your challenge expired - you will not anymore receive full XPs!</b>
              </p>
          }
        </p>
      </div>
  );
};
