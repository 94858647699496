import './CodeEditorBase.scss';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHackathonStore } from '../../store';
import { CodeFlaskReact } from '../../3rdparty/CodeFlaskEditor';
import { generateAttributesData } from '../../lib/hackathonProblemLib';

export const CodeEditorBase = () => {
  const userCode = useHackathonStore(state => state.currentChallenge.userCode);
  const activeProblem = useHackathonStore(state => state.currentChallenge.problem);
  const wasSolved = useHackathonStore(state => state.currentChallenge.successState !== null);

  const pushSolutionAction = useHackathonStore(state => state.submitSolution);
  const setUserCodeAction = useHackathonStore(state => state.setUserCode);
  const surrender = useHackathonStore(state => state.surrender);

  const [hasReturnStmt, setHasReturnStmt] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);


  const submitEmptySolution = async () => {
    setError(null);

    await surrender();
  };

  const submitSolution = async () => {
    setError(null);

    if (userCode.indexOf('return') === -1) {
      setHasReturnStmt(false);
      return;
    } else {
      setHasReturnStmt(true);
    }

    try {
      // eslint-disable-next-line no-new-func
      const f = new Function('input', 'attributes', userCode);
      const attributes = generateAttributesData(activeProblem!.problem);

      const result = f(activeProblem!.problem.problem, attributes);

      await pushSolutionAction(result);
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setHasReturnStmt(null);
    }
  };

  const topLineCode = `function hackathon(input, attributes) {`;
  const bottomLineCode = `}`;

  return (
      <div className="code-editor-root">
        { wasSolved ?
            <div className="lock-overlay">
              <div>
                <p>
                  <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
                </p>
                <p>
                  { wasSolved ? 'Solution submitted ->' : '' }
                </p>
              </div>
            </div>
            : null }
        <div className="row">
          <div className="col-12 mb-3">
            <div style={ { display: 'flex' } }>
              <div>
                <button className="btn btn-secondary" onClick={ submitEmptySolution } disabled={ !activeProblem }>Surrender 😒</button>
              </div>
              <div style={ { flexGrow: 1 } }>
                { hasReturnStmt === false ? <p className="alert alert-warning">Hmm... Did you miss to add a return statement? Check your code and submit again.</p> : '' }
                { error !== null ? <p className="alert alert-warning">There was an error when executing your code: <b>{ error }</b></p> : '' }
              </div>
              <div>
                { !wasSolved
                    ? <button className="btn btn-primary" onClick={ submitSolution } disabled={ !activeProblem }>Submit solution</button>
                    : ''
                }
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="code-editor-wrapper">
              <CodeFlaskReact containerId="codeflask-top-line"
                              lineNumbers={ false }
                              readonly={ true }
                              code={ topLineCode }
              />
              <CodeFlaskReact containerId="codeflask-container"
                              lineNumbers={ true }
                              code={ userCode }
                              onChange={ (e: string) => setUserCodeAction(e) }
              />
              <CodeFlaskReact containerId="codeflask-bottom-line"
                              lineNumbers={ false }
                              readonly={ true }
                              code={ bottomLineCode }
              />
            </div>
          </div>
        </div>
      </div>
  );
};
