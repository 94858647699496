import { useData } from '../../hooks/useData';
import { useHackathonStore } from '../../store';
import { Challenge } from '../../types/challenge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { range } from '../../lib/range';
import { apiBase } from '../../api-config';

interface ChallengeListProps {
  selectChallenge: Function;
}

export const ChallengeList = ({ selectChallenge }: ChallengeListProps) => {
  const [infoRow, setInfoRow] = useState<string | null>(null);
  const currentHackathon = useHackathonStore(state => state.hackathonId);
  const [challenges, _] = useData<Challenge[]>(`${ apiBase }/api/v1/hackathlon/${ currentHackathon }/challenges`);

  const toggleInfoRow = (row: string) => {
    if (infoRow === row) {
      selectChallenge(null);
      setInfoRow(null);
    }

    selectChallenge(challenges?.find(x => x.name === row) ?? null);
    setInfoRow(row);
  };

  return (
      <div>
        <table className="table table-striped">
          <thead>
          <tr>
            <th>
              Challenge
            </th>
            <th>
              Level
            </th>
            <th>
              XPs
            </th>
            <th colSpan={ 2 }>
              Attempts
            </th>
          </tr>
          </thead>
          <tbody>
          { challenges?.map(x => (
              <tr key={ x.id }>
                <td>
                  { x.name }

                  <span className="p-2 cursor pointer"><FontAwesomeIcon icon="info-circle" onClick={ _ => toggleInfoRow(x.name) } /></span>
                </td>
                <td>
                  { range(x.level).map(_ => (
                      <FontAwesomeIcon icon="star" />
                  )) }
                </td>
                <td>
                  { x.xp } within { x.time_limit }s
                </td>
                <td>
                  { x.attempts === 1
                      ? <>1</>
                      : <>{ x.attempts - x.remaining_attempts }/{ x.attempts }</>
                  }
                </td>
                <td className="text-right">
                  { x.remaining_attempts > 0
                      ? <button className="btn btn-primary btn-sm" onClick={ _ => toggleInfoRow(x.name) }>
                        Choose
                      </button>
                      : null
                  }</td>
              </tr>
          )) }
          </tbody>
        </table>
      </div>
  );
};
