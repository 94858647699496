import './LoginPage.scss';
import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useHackathonStore } from '../store';

export const LoginPage = () => {
  const saveToken = useHackathonStore(state => state.setToken);
  const [token, setToken] = useState('');
  const buildDateTime = process.env.REACT_APP_CURRENT_DATE_TIME;

  const validateToken = () => {
    saveToken(token);
  };

  return (
      <div className="card login p-3 border border-primary rounded">
        <div className="card-title text-center">
          <h1>Login</h1>
        </div>
        <div className="card-body">
          <Form>
            <Form.Group className="text-center mb-3">
              <Form.Label>Enter your token</Form.Label>
              <Form.Control type="text" onChange={ e => setToken(e.target.value) }></Form.Control>
            </Form.Group>
            <div className="text-center">
              <Button onClick={ validateToken }>
                Let's go!
              </Button>
            </div>
          </Form>
        </div>
        <div className="card-body small text-center">
          Build from { buildDateTime }
        </div>
      </div>
  );
};
