import './ChallengeDetail.scss';

import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Challenge } from '../../types/challenge';
import { range } from '../../lib/range';
import { useHackathonStore } from '../../store';

interface ChallengeDetailProps {
  challenge: Challenge | null;
  onClear?: Function | null;
  showActions?: boolean;
}

export const ChallengeDetail = ({ challenge, onClear, showActions = true }: ChallengeDetailProps) => {
  const [confirmationMode, setConfirmationMode] = useState(false);
  const _ = useHackathonStore(state => state.hackathonId);
  const __ = useHackathonStore(state => state.loginToken);
  const startChallenge = useHackathonStore(state => state.startChallenge);

  const handleClear = () => {
    if (onClear) onClear();
  };

  const onStartChallenge = async () => {
    if (challenge === null) return;

    if (!confirmationMode) {
      setConfirmationMode(true);
      return;
    }

    try {
      startChallenge(challenge);
    } catch (e) {
      // TODO: make nice
      console.error(e);
    }
  };

  return (
      <div className="challenge-detail">
        { challenge ? (
            <div>
              <h3>{ challenge.name }</h3>

              <p>
                { range(challenge.level).map(_ => (
                    <FontAwesomeIcon icon="star" />
                )) }
              </p>

              <p className="description">
                <ReactMarkdown>{ challenge.description }</ReactMarkdown>
              </p>

              <p>
                You can get a maximum of <b>{ challenge.xp } XPs</b> for this challenge if you solve it within <b>{ challenge.time_limit } seconds</b>.
                { challenge.attempts > 0
                    ? <>If you retry this challenge (up to { challenge.attempts } times is allowed), you will still get up to { challenge.xp_reduced } XPs.</>
                    : <>You may not retry this challenge - sorry!</>
                }
              </p>

              <p>
                { challenge.remaining_attempts === challenge.attempts
                    ? <>You never tried this challenge before.</>
                    : <>You may restart this challenge still { challenge.remaining_attempts } times.</>
                }
              </p>

              { challenge.examples?.length > 0
                  ? <div className="examples">
                    <h4>Examples</h4>
                    <ul>
                      { challenge.examples.map(example => (
                          <li key={ example.problem }>
                            For a given problem <pre>{ example.problem }</pre>,<br />
                            the expected solution would be <pre>{ example.solution }</pre>
                          </li>
                      )) }
                    </ul>
                  </div>
                  : <></> }

              { showActions
                  ? <div style={ { display: 'flex' } } className="mb-3">
                    <div>
                      <button className="btn btn-secondary" onClick={ _ => handleClear() }>
                        Back
                      </button>
                    </div>
                    <div style={ { flexGrow: 1 } }></div>
                    <div style={ { textAlign: 'right' } }>
                      { confirmationMode ?
                          <p className="alert alert-info confirmation-box">
                            After you started this challenge, you either finish the challenge or wait until it times out.
                          </p> : null }
                      <button className={ confirmationMode ? 'btn btn-info' : 'btn btn-primary' }
                              onClick={ _ => onStartChallenge() }>
                        { confirmationMode ? <>Are you sure?</> : <>Start this challenge</> }
                      </button>
                    </div>
                  </div>
                  : <></>
              }
            </div>
        ) : '' }
      </div>
  );
};
