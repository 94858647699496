import React from 'react';

import './App.scss';
import { useHackathonStore } from './store';

import { LoginPage } from './login/LoginPage';
import { ChooseHackathonPage } from './login/ChooseHackathonPage';
import { HackathonLayout } from './hackathon/HackathonLayout';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar, faInfoCircle, faLock } from '@fortawesome/free-solid-svg-icons';

library.add(faStar, faInfoCircle, faLock);

function App() {
  const hasToken = useHackathonStore(state => !!state.loginToken);
  const hasChosenHackathon = useHackathonStore(state => !!state.hackathonId);

  return (
      <div className="app">
        { !hasToken ? <LoginPage></LoginPage> : null }
        { hasToken && !hasChosenHackathon ? <ChooseHackathonPage></ChooseHackathonPage> : null }
        { hasToken && hasChosenHackathon ? <HackathonLayout></HackathonLayout> : null }
      </div>
  );
}

export default App;
