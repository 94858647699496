import { Challenge } from '../../types/challenge';
import { ChallengeList } from './ChallengeList';
import { ChallengeDetail } from './ChallengeDetail';
import { ActiveChallenge } from './ActiveChallenge';
import { useHackathonStore } from '../../store';
import { useData } from '../../hooks/useData';
import { apiBase } from '../../api-config';

interface ChallengeBaseProps {
  selectChallenge: Function;
  selectedChallenge: Challenge | null,
}

export const ChallengeBase = ({ selectChallenge, selectedChallenge }: ChallengeBaseProps) => {
  const activeProblem = useHackathonStore(state => state.currentChallenge.problem);
  const currentHackathon = useHackathonStore(state => state.hackathonId);
  const [challenges, __] = useData<Challenge[]>(`${ apiBase }/api/v1/hackathlon/${ currentHackathon }/challenges`);

  return (
      <div>
        { activeProblem && challenges
            ? <ActiveChallenge key={ activeProblem.id } problem={ activeProblem } challenge={ challenges.find(x => x.name === activeProblem.challenge_name)! } />
            : !selectedChallenge
                ? <ChallengeList selectChallenge={ (x: Challenge) => selectChallenge(x) } />
                : <ChallengeDetail onClear={ (_: any) => selectChallenge(null) } challenge={ selectedChallenge } />
        }
      </div>
  );
};
