import { useHackathonStore } from '../../store';

interface SolveResponseTabProps {
  selectChallenge: Function;
}

export const SolveResponseTab = ({ selectChallenge }: SolveResponseTabProps) => {
  const isSuccess = useHackathonStore(state => state.currentChallenge.successState);
  const submittedSolution = useHackathonStore(state => state.currentChallenge.submittedSolution);
  const clearLocalSolutionAction = useHackathonStore(state => state.clearLocalSolution);
  const clearChallenge = useHackathonStore(state => state.clearCurrentChallenge);

  const resetChallenge = () => {
    clearChallenge();
    selectChallenge(null);
  };

  if (isSuccess === null) return (<></>);

  const tryAgain = () => clearLocalSolutionAction();

  return (
      <div>
        { isSuccess
            ? <>
              <p>
                <b>Congrats, you solved the challenge!</b>
              </p>
              <p>
                <button className="btn btn-outline-primary" onClick={ resetChallenge }>Choose next challenge</button>
              </p>
            </>
            : <>
              <p>
                <b>Failure is an attitude, not an outcome.</b>
              </p>
              <p>
                It looks like your solution was not correct. You may try again. Your code returned the following result:
              </p>
              <pre>{ submittedSolution }</pre>
              <p>
                <button className="btn btn-outline-primary" onClick={ tryAgain }>Try again</button>
              </p>
            </> }
      </div>
  );
};
