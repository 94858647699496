import React from 'react';
import CodeFlask from 'codeflask';

class CodeFlaskReact extends React.Component {
  containerId = 'react-clodeflask-root';
  readonly = false;
  lineNumbers = false;

  constructor(props, context) {
    super(props, context);

    if (props.containerId) this.containerId = props.containerId;
    if (props.readonly) this.readonly = !!props.readonly;
    if (props.lineNumbers) this.lineNumbers = !!props.lineNumbers;

    CodeFlaskReact.defaultProps = { id: this.containerId };
  }

  componentDidMount() {
    this.codeFlask = new CodeFlask(`#${this.containerId}`, {
      id: this.containerId,
      language: 'js',
      readonly: this.readonly,
      lineNumbers: this.lineNumbers,
      onChange: () => {
      }
    });

    if (this.props.readonly) {
      this.codeFlask.enableReadonlyMode();
    }

    if (this.props.code) {
      this.codeFlask.updateCode(this.props.code);
    }

    if (this.props.onChange) {
      this.codeFlask.onUpdate(this.props.onChange);
    }

    if (this.props.editorRef) {
      this.props.editorRef(this.codeFlask);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.code !== this.codeFlask.getCode()) {
      this.codeFlask.updateCode(newProps.code);
    }
  }

  render() {
    return <div id={this.containerId} />;
  }
}

export { CodeFlaskReact };
