import { useHackathonStore } from '../store';
import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Response } from '../types/response';
import { getHeaders } from '../lib/http';

export const useData = <T>(url: string, method: 'get' | 'post' = 'get'): [T | null, null | AxiosError] => {
  const token = useHackathonStore(state => state.loginToken);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;

    let f = null;
    if (method === 'post') f = (url: string, headers: object): Promise<AxiosResponse<Response<T>>> => axios.post(url, null, headers);
    else f = (url: string, headers: object): Promise<AxiosResponse<Response<T>>> => axios.get(url, headers);

    f(url, getHeaders(token))
        .then(response => !ignore && setData(response.data.data))
        .catch(e => setError(e));

    return () => {
      ignore = true;
    };
  }, [token, url, method]);

  return [data, error];
};
