import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Challenge } from './types/challenge';
import { Problem } from './types/problem';
import axios from 'axios';
import { getHeaders } from './lib/http';
import { SolveResponse } from './types/solveResponse';
import { apiBase } from './api-config';

type setTokenF = (token: string | null) => void;
type startChallengeF = (challenge: Challenge) => void;
type setUserCodeF = (code: string) => void;
type setHackathonIdF = (id: string) => void;
type submitSolutionF = (solution: string) => Promise<void>;
type surrenderF = () => Promise<void>;
type patchChallengeF = (challenge: Challenge, problem: Problem) => void;
type clearCurrentChallengeF = () => void;

export interface HackathonStore {
  /**
   * The access token
   */
  loginToken: string | null;
  /**
   * The slug of the current hackathon
   */
  hackathonId: string | null;
  currentChallenge: {
    userCode: string;
    problem: Problem | null;
    challenge: Challenge | null;
    successState: boolean | null;
    submittedSolution: string | null;
  };
  // mutators
  setToken: setTokenF;
  setHackathonId: setHackathonIdF;
  setUserCode: setUserCodeF;
  startChallenge: startChallengeF;
  patchChallenge: patchChallengeF;
  submitSolution: submitSolutionF;
  surrender: surrenderF;
  clearLocalSolution: Function;
  clearCurrentChallenge: clearCurrentChallengeF;
}

const submitSolution = async (hackathonId: string, problemId: number, solution: string, loginToken: string): Promise<object> => {
  try {
    const response = await axios.post<SolveResponse>(
        `${ apiBase }/api/v1/hackathlon/${ hackathonId }/solve/${ problemId }`,
        JSON.stringify({ solution }),
        getHeaders(loginToken));

    if (response.data.success) {
      return { userCode: '', problem: null, challenge: null, successState: true, submittedSolution: solution };
    }

    return { successState: false, submittedSolution: solution };
  } catch (error) {
    return { successState: false, submittedSolution: solution };
  }
};

const surrender = async (hackathonId: string, problemId: number, loginToken: string): Promise<void> => {
  await axios.patch(`${ apiBase }/api/v1/hackathlon/${ hackathonId }/surrender/${ problemId }`, null, getHeaders(loginToken));
};

const startChallenge = async (hackathonId: string, challengeId: number, loginToken: string) => {
  const problem = (await axios.get(`${ apiBase }/api/v1/hackathlon/${ hackathonId }/start/${ challengeId }`, getHeaders(loginToken))).data.data;

  return { problem };
};

export const useHackathonStore = create<HackathonStore>()(
    persist(
        (set, get) => {
          return {
            loginToken: null,
            hackathonId: null,
            currentChallenge: {
              userCode: '',
              problem: null,
              challenge: null,
              successState: null,
              submittedSolution: null,
            },
            setToken: (token: string | null) => set(_ => ({ loginToken: token })),
            setHackathonId: (id: string) => set(_ => ({ hackathonId: id })),
            setUserCode: (code: string) => set(state => ({ currentChallenge: { ...state.currentChallenge, userCode: code } })),
            startChallenge: async (challenge: Challenge) => {
              const state = get();
              const patch = await startChallenge(state.hackathonId!, challenge.id, state.loginToken!);

              set({
                currentChallenge: {
                  ...state.currentChallenge,
                  challenge,
                  ...patch
                }
              });
            },
            patchChallenge: (challenge: Challenge, problem: Problem) => set(state => ({
              currentChallenge: {
                ...state.currentChallenge,
                challenge,
                problem,
              }
            })),
            submitSolution: async (solution: string) => {
              const state = get();
              const patch = await submitSolution(state.hackathonId!, state.currentChallenge.problem!.id, solution, state.loginToken!);

              set({
                currentChallenge: {
                  ...state.currentChallenge,
                  ...patch
                }
              });
            },
            surrender: async () => {
              const state = get();
              await surrender(state.hackathonId!, state.currentChallenge.problem!.id!, state.loginToken!);

              state.clearCurrentChallenge();
            },
            clearLocalSolution: () => set(state => ({ currentChallenge: { ...state.currentChallenge, successState: null, submittedSolution: null } })),
            clearCurrentChallenge: () => {
              const state = get();

              set({
                currentChallenge: {
                  ...state.currentChallenge,
                  userCode: '',
                  challenge: null,
                  problem: null,
                  successState: null,
                  submittedSolution: null,
                }
              });
            },
          };
        },
        {
          name: 'hackathon',
          storage: createJSONStorage(() => localStorage),
          partialize: state => ({
            loginToken: state.loginToken,
            hackathonId: state.hackathonId,
            currentChallenge: {
              // do not load problem or current challenge into persistent store
              // because this data must be retrieved from the API anyway when refreshing the page
              userCode: state.currentChallenge.userCode,
              successState: null,
            }
          })
        })
);
