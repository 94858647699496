/**
 * Generate a string representation of the attributes object
 */
export const generateAttributesObject = (problem: object): string => {
  const copy = generateAttributesData(problem);

  return JSON.stringify(copy, null, 2);
};

/**
 * Clones the attributes object and removes the problem attribute
 */
export const generateAttributesData = (problem: object): string => {
  const copy = structuredClone(problem);
  delete copy.problem;

  return copy;
};
